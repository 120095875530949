import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Container = () => (
  <Layout>
    <SEO title="Home" />
    <div className="home">
      <header
        className="color-bg light-typo padding-top-bottom bg-image detail-header"
        style={{
          backgroundImage: "url(/images/etc/reservation_header.jpg)",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <h1 className="page-title">예약 종합 안내</h1>

          <p className="page-subtitle">
            다누리 전체 객실의 정보와 요금을 한 눈에 보여드릴게요.
          </p>
        </div>
      </header>

      <article className="post single-post">
        <div className="container fade-up">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1">
              <div className="post-content">
                <div>
                  <h2 className="text-center">객실 정보</h2>
                  <div>
                    <table className="table table-brodered">
                      <tbody>
                        <tr className="active">
                          <td>객실명</td>
                          <td>구조</td>
                          <td>크기</td>
                          <td>기준 / 최대인원</td>
                        </tr>
                        <tr className="">
                          <td>꽃이슬</td>
                          <td>거실+방 (침대룸1+화장실1)</td>
                          <td>36㎡ (11평)</td>
                          <td>2명 / 4명</td>
                        </tr>
                        <tr className="">
                          <td>별</td>
                          <td>원룸 (침대룸1+화장실1)</td>
                          <td>30㎡ (9평)</td>
                          <td>2명 / 4명</td>
                        </tr>
                        <tr className="">
                          <td>산</td>
                          <td>원룸 (침대룸1+화장실1)</td>
                          <td>30㎡ (9평)</td>
                          <td>2명 / 4명</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <br />

                <div>
                  <h2 className="text-center">요금</h2>
                  <div>
                    <p>
                      <span style={{ fontSize: "14px", color: "#7a7c7f" }}>
                        *<b>성수기</b>: 7월 20일 ~ 8월 20일
                      </span>
                      <br />
                      <span style={{ fontSize: "14px", color: "#7a7c7f" }}>
                        *<b>인원 추가</b>: 1인 추가시 10,000원이 추가됩니다.
                        (영,유아도 추가 유료 인원에 포함)
                      </span>
                      <br />
                      <span style={{ fontSize: "14px", color: "#7a7c7f" }}>
                        *<b>숯불&그릴 사용시</b>: 4인 기준 10,000원
                      </span>
                    </p>
                    <table className="table table-brodered">
                      <tbody>
                        <tr className="active">
                          <td>객실명</td>
                          <td>주중(일~목)</td>
                          <td>금/토요일</td>
                          <td>공휴일 전 날</td>
                          <td>성수기</td>
                        </tr>
                        <tr className="">
                          <td>꽃이슬</td>
                          <td>80,000원</td>
                          <td>100,000원</td>
                          <td>120,000원</td>
                          <td>120,000원</td>
                        </tr>
                        <tr className="">
                          <td>별</td>
                          <td>70,000원</td>
                          <td>80,000원</td>
                          <td>80,000원</td>
                          <td>100,000원</td>
                        </tr>
                        <tr className="">
                          <td>산</td>
                          <td>70,000원</td>
                          <td>80,000원</td>
                          <td>80,000원</td>
                          <td>100,000원</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <br />

                <div>
                  <h2 className="text-center">입금 정보</h2>
                  <p>
                    <span style={{ fontSize: "14px" }} className="text-danger">
                      * 예약금을 입금하셔야 예약 완료가 됩니다. 입금 후 전화
                      주세요. (010-4709-7968{" "}
                      <span style={{ fontSize: "10px" }}>또는</span>{" "}
                      010-6471-7968)
                    </span>
                  </p>
                  <table className="table table-brodered">
                    <tbody>
                      <tr className="">
                        <td className="active">은행</td>
                        <td>농협 649-02-098378</td>
                      </tr>
                      <tr className="">
                        <td className="active">예금주</td>
                        <td>변재호</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <br />

              <div>
                <h2 className="text-center">문의</h2>
                <table className="table table-brodered">
                  <tbody>
                    <tr className="">
                      <td className="active">휴대폰</td>
                      <td>
                        010-4709-7968{" "}
                        <span style={{ fontSize: "12px" }}>또는</span>{" "}
                        010-6471-7968
                      </td>
                    </tr>
                    <tr className="">
                      <td className="active">유선</td>
                      <td>061-852-7968</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="text-center">
            <a className="btn btn-qubico" href="/reservation/reserve">
              <i className="fa fa-shopping-cart"></i>예약하기
            </a>
          </div>
        </div>
      </article>
    </div>
  </Layout>
)

export default Container
